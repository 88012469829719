<template>
    <div style="width: 100%;">
        <GmapMap
        :center="coords"
        :zoom="7"
        map-type-id="terrain"
        style="width: 100%; height: 300px"
        @click="mark"
        >
            <GmapMarker
            :position="coords"
            :clickable="true"
            :draggable="false"
        />
        </GmapMap>
    </div>
</template>

<script>
export default {
    props: ["latest_coords"],
    created() {
        this.coords = this.latest_coords;
    },
    data() {
        return {
            coords: {
                lat: 0,
                lng: 0
            }
        }
    },
    methods: {
        mark(e) {
            this.coords.lat = e.latLng.lat();
            this.coords.lng = e.latLng.lng();
            this.$emit("setCoords", this.coords);
        }
    },
}
</script>