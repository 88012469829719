<template>
  <div>
    <div class="sections">
      <h3 class="title text-center">{{ type }}s</h3>
      <div v-for="(sec, i) in sections" :key="i" class="section">
        <div @click="remove(i)" class="remove">
          <i class="fa fa-times"></i>
        </div>
        <h4 class="text-center">{{ type }} ({{ i + 1 }})</h4>
        <hr />
        <div class="row">
          <div class="col-md-12 field">
            <div class="form-group">
              <label>{{ type }} title</label>
              <input
                @change="update"
                class="form-control"
                v-model="sections[i].title"
                placeholder="العنوان"
              />
            </div>
          </div>
          <div class="col-md-12 field">
            <div class="form-group">
              <label>{{ type }} description</label>
              <textarea
                @change="update"
                class="form-control"
                v-model="sections[i].description"
                placeholder="الوصف"
              />
            </div>
          </div>
        </div>
        <hr />
      </div>
      <button @click="increase" class="btn btn-link">Add one more</button>
    </div>
  </div>
</template>

<script>
export default {
  props: ["type", "prop_sections"],
  created() {
    this.sections = this.prop_sections;
  },
  data() {
    return {
      sections: [],
    };
  },
  methods: {
    increase() {
      this.sections.push({ title: "", description: "" });
    },
    remove(i) {
      this.sections.splice(i, 1);
    },
    update() {
      this.$emit("setSections", this.sections);
    },
  },
};
</script>
